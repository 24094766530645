import React, { useEffect, useState } from 'react';
import "pages/zoomConnect/_zoomConnect.scss";
import { Loader } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../common";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { authExtraActions } from "../../reduxStore/actions";

function ZoomConnect() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search);
  const [zoomAuthCode] = useState(queryParameters.get('code'));

  useEffect(() => {
    if (!zoomAuthCode) {
      toast.error("Zoom Auth Code isn't provided");
    } else {
      dispatch(authExtraActions.connectZoom({'zoom_auth_code': zoomAuthCode})).then((response) => {
        if (response?.type === "auth/connectZoom/fulfilled") {
          toast.success('Zoom Connected Successfully');
        }
      });
    }
    navigate(routes.UPLOAD);
  }, [dispatch, navigate, zoomAuthCode]);

  return (
    <div className="landing">
      <Loader/>
      Connecting Zoom Account
    </div>
  );
}

export default ZoomConnect;
