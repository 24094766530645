import './App.scss';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes as Switch } from 'react-router-dom';
import { globals, routes } from "common";
import { ToastContainer } from "react-toastify";
import { Landing, Summary, Transcription, ZoomConnect, Upload } from "pages";
import { Layout } from "components";
import { useSelector } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';

const RequireAuth = () => {
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);

  return (
    isUserLoggedIn ? <Outlet/> : <Navigate to={routes.LANDING}/>
  );
};


function App() {
  return (
    <div className='app'>
      <Router>
        <Switch>
          <Route path='/' element={<Layout/>}>
            <Route path={routes.LANDING} element={<Landing/>}/>
            <Route element={<RequireAuth/>}>
              <Route path={routes.CONNECT_ZOOM} element={<ZoomConnect/>}/>
              <Route path={routes.UPLOAD} element={<Upload/>}/>
              <Route path={routes.TRANSCRIPTIONS} element={<Transcription/>}/>
              <Route path={routes.TRANSCRIPTION_DETAIL} element={<Transcription/>}/>
              <Route path={routes.SUMMARIES} element={<Summary/>}/>
              <Route path={routes.SUMMARY_DETAIL} element={<Summary/>}/>
              <Route path='*' element={<Navigate to='/'/>}/>
            </Route>
          </Route>
        </Switch>
      </Router>
      <ToastContainer autoClose={globals.TOAST_TIMER}/>
    </div>
  );
}

export default App;
